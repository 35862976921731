<template>
  <div id="generated-link-page" class="row">
    <left-info-panel></left-info-panel>
    <div class="col-6 d-flex justify-content-center align-items-center bg-secondary sc-right-block">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="card sc">
                <div class="card-body sc">
                  <label class="form-label d-block sc sc-fs-14">Крок 3 з 3</label>
                  <h4 class="card-title sc">Запроси учасників</h4>
                  <hr class="sc-hr">
                  <div class="row row-cols-2 sc">
                    <div class="col d-flex justify-content-start align-items-center p-0 sc-w-80p">
                      <strong class="fs-6 fw-bolder w-100" style="font-size: 18px;">{{ companyName }}</strong>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center p-0 sc-w-20p">
                      <label class="col-form-label m-0">{{ playersCount }} ос.</label>
                    </div>
                  </div>
                  <hr class="sc-hr">
                  <label class="form-label d-block sc">Запрошення</label>
                  <input type="text" class="generated-link" v-on:focus="$event.target.select()"
                         ref="linkInput" readonly v-model="gameLinkUrl">
                  <div class="row row-cols-2 w-100 sc-reg-copy">
                    <div class="col d-flex justify-content-start align-items-center p-0 sc-w-80p">
                      <a @click="copyUrl" href="#">Скопіювати повторно</a>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center p-0 sc-w-20p">
                      <label class="col-form-label fs-6 fw-light m-0 sc-lb text-light">Скопійовано</label>
                    </div>
                  </div>
                  <button class="btn btn-primary sc-reg" type="button" @click.self.prevent="signIn">ПРОДОВЖИТИ</button>
                </div>
              </div>
            </div>
          </div>
          <div class="invisible row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start sc" role="alert">
                <i class="fas fa-info-circle"></i>
                <span class="d-inline-block">
                  <strong></strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import LeftInfoPanel from "@/components/leftInfoPanel";
import SocialLinks from "@/components/socialLinks.vue";

export default {
  name: "generated-link",
  components: {SocialLinks, LeftInfoPanel},
  data() {
    return {
      playersCount: "",
      companyName: "",
      gameLink: ""
    }
  },
  computed: {
    gameLinkUrl() {
      return window.location.protocol + "//" + window.location.host + "/#/login/" + this.gameLink;
    }
  },
  created() {
    let self = this;
    let teamQuery = (this.$route.params.team !== undefined) ? "?t=" + this.$route.params.team: "";
    this.$axios.get('/active-link/' + teamQuery).then(function (response) {
      self.playersCount = response.data["players_count"];
      self.companyName = response.data["company_name"];
      self.gameLink = response.data["game_link"];
      console.log(response);
      setTimeout(()=>{
        self.$refs.linkInput.focus();
        document.execCommand('copy');
      }, 1000);
    }).catch(function (error) {
      alert(error);
    });
  },
  methods: {
    signIn(event) {
      window.location.href = "/#/login/" + this.gameLink
    },
    copyUrl() {
      this.$refs.linkInput.focus();
      document.execCommand('copy');
    }
  }
}
</script>

<style scoped>
  #generated-link-page {
    height: 100%;
    padding: 20px;
    margin: 0;
  }

  .generated-link {
    padding-left: 25px;
    margin-left: 25px;
    width: 300px;
    height: 56px;
    border-radius: 4px;
  }

  .sc-right-block {
    height: 100%;
    border-radius: 10px;
  }

  .card.sc {
    width: 400px;
    height: 460px;
    margin: 0;
    position: relative;
  }

  .card-body.sc {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 0 25px;
  }

  .card-title.sc {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-left: 25px;
    margin-bottom: 27px;
  }

  .sc-hr {
    width: 100%;
    margin: 0 0 0 0;
    padding-right: 0;
    padding-left: 0;
    border-color: #99B2F1;
  }

  .row.sc {
    width: 100%;
    margin: 20px 0;
    padding-right: 25px;
    padding-left: 25px;
  }

  .form-label.sc {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 8px;
    margin-left: 25px;
    font-size: 16px;
  }

  .sc-reg-copy {
    margin: 20px 0;
    padding-right: 25px;
    padding-left: 25px;
  }

  .sc-reg hr {
    border-color: #99B2F1;
  }

  .sc-w-80p {
    width: 80%
  }

  .sc-w-20p {
    width: 20%
  }

  .sc-lb {
    font-size: 14px;
    color: #C4C4C4;
  }

  .sc-fs-14 {
    font-size: 14px!important;
  }

  .btn.btn-primary.sc-reg {
    width: 300px;
    height: 56px;
    margin-left: 25px;
    font-size: 16px;
    font-weight: 700;
  }

  .sc-error-block {
    --bs-danger: #dc3545;
    --bs-danger-rgb: 220,53,69;
  }

  .alert.sc {
    padding-right: 0;
    padding-left: 50px;
    background: rgb(254,88,88);
    width: 400px;
    margin: 7px 0 0;
  }

  .alert.sc i {
    margin-right: 15px;
    color: rgb(255,255,255);
    font-size: 25px;
  }

  .alert.sc span {
    color: rgb(255,255,255);
    position: relative;
  }
</style>