<template>
  <div id="create-game-page" class="row">
    <left-info-panel></left-info-panel>
    <div class="col-6 d-flex justify-content-center align-items-center bg-secondary sc-right-block">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="card sc">
                <div class="card-body sc">
                  <label class="form-label d-block sc sc-fs-14">Крок 2 з 3</label>
                  <h4 class="card-title sc">Новий тест</h4>
                  <label class="form-label d-block sc">Назва команди</label>
                  <input type="text" placeholder="" class="team-name" :disabled="isTeamCreated"
                         v-model="companyName" v-bind:style="{'border-color':(registerError === '')?'':'#FF4D4D'}"/>
                  <div class="row row-cols-2 w-100 sc-reg-company">
                    <div class="col d-flex justify-content-start align-items-center p-0 sc-w-80p">
                      <label class="col-form-label">Кількість людей</label>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center p-0 sc-w-20p">
                      <label class="col-form-label fs-6 fw-light m-0 sc-gen">{{ playersCount }}/10</label>
                    </div>
                  </div>
                  <div class="row row-cols-2 w-100 sc-reg-slider">
                    <div class="col d-flex justify-content-start align-items-center p-0 w-100">
                      <vue-slider v-model="playersCount" v-bind="sliderOptions"></vue-slider>
                    </div>
                  </div>
                  <button class="btn btn-primary sc-gen" type="button" @click.self.prevent="register"
                          v-bind:class="regButtonClassObject">ПРОДОВЖИТИ</button>
                </div>
              </div>
            </div>
          </div>
          <div :class="{invisible: (registerError === '')}" class="row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start sc" role="alert">
                <i class="fas fa-info-circle"></i>
                <span class="d-inline-block">
              <strong>{{ registerError }}</strong>
        </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import LeftInfoPanel from "@/components/leftInfoPanel";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import {csrfAjaxMixin} from "@/mixins/csrf";
import SocialLinks from "@/components/socialLinks.vue";

export default {
  name: "create-game",
  components: {SocialLinks, LeftInfoPanel, VueSlider},
  mixins: [csrfAjaxMixin],
  data() {
    return {
      registerError: "",
      playersCount: "2",
      companyName: "",
      isTeamCreated: false,
      sliderOptions: {
        dotSize: 14,
        width: '100%',
        height: 3,
        min: 2,
        max: 10,
        interval: 1,
        tooltip: 'none',
        dotAttrs: void 0,
        process: true,
        dotStyle: {
          "border-radius": "25%",
          backgroundColor: "#FFF",
          border: "2px solid #4D78E7",
          left: "5%"
        },
        railStyle: {
          backgroundColor: '#99B2F1'
        },
        processStyle: {
          backgroundColor: '#4D78E7'
        },
        stepActiveStyle: {
          backgroundColor: '#99B2F1'
        },
      }
    }
  },
  computed: {
    regButtonClassObject: function () {
      return {
        disabled: this.companyName === ""
      }
    }
  },
  watch: {
    companyName: function (newCompanyName, oldCompanyName) {
      if (newCompanyName !== "") {
        this.registerError = "";
      }
    }
  },
  created() {
    let self = this;
    this.$axios.get('/register/').then(function (response) {
      self.companyName = response.data["company_name"];
      if (self.companyName !== "") {
        self.isTeamCreated = true;
        self.sliderOptions.disabled = true;
        self.sliderOptions.clickable = false;
        self.playersCount = response.data["players_count"];
      }
      console.log(response);
    }).catch(function (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      console.log(error.config);
    });
  },
  methods: {
    register: function (event) {
      if (this.companyName === "") {
        this.registerError = "Заповніть порожнє поле";
      } else {
        let self = this;
        let data = {
          "company_name": this.companyName,
          "players_count": this.playersCount
        };
        this.$axios.post('/register/', data,).then(function (response) {
          console.log(response);
          self.$router.push('/link/' + self.companyName)
        }).catch(function (error) {
          if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
            self.registerError = error.response.data.error;
          } else if (error.request) {
            console.error(error.request);
          } else {
            console.error('Error', error.message);
          }
          console.error(error.config);
        });
      }
    }
  }
}
</script>

<style scoped>
#create-game-page {
  height: 100%;
  padding: 20px;
  margin: 0;
}

.sc-right-block {
  height: 100%;
  border-radius: 10px;
}

.card.sc {
  width: 400px;
  height: 560px;
  margin: 0;
  position: relative;
}

.card-body.sc {
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  padding: 0 25px;
}

.card-title.sc {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-left: 25px;
  margin-bottom: 27px;
}

.form-label.sc {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 8px;
  margin-left: 25px;
  font-size: 16px;
}

.team-name {
  padding-left: 25px;
  margin-left: 25px;
  width: 300px;
  height: 56px;
  border-radius: 4px;
}

.sc-reg-company, .sc-reg-slider {
  margin: 20px 0;
  padding-right: 25px;
  padding-left: 25px;
}

.sc-warning {
  padding-right: 25px;
  padding-left: 25px;
}

.sc-w-80p {
  width: 80%
}

.sc-w-20p {
  width: 20%
}

.sc-fs-14 {
  font-size: 14px!important;
}

.btn.btn-primary.sc-gen {
  width: 300px;
  height: 56px;
  margin-left: 25px;
  font-size: 16px;
  font-weight: 700;
}

.sc-hr {
  width: 100%;
  margin: 30px 0 20px 0;
  padding-right: 0;
  padding-left: 0;
  border-color: #99B2F1;
}

.sc-error-block {
  --bs-danger: #dc3545;
  --bs-danger-rgb: 220,53,69;
}

.alert.sc {
  padding-right: 0;
  padding-left: 50px;
  background: rgb(254,88,88);
  width: 400px;
  margin: 7px 0 0;
}

.alert.sc i {
  margin-right: 15px;
  color: rgb(255,255,255);
  font-size: 25px;
}

.alert.sc span {
  color: rgb(255,255,255);
  position: relative;
}

</style>