import $ from "jquery";

export const csrfAjaxMixin = {
    data: function () {
        return {
            csrftoken: ""
        }
    },
    created: function () {
        console.log("mixin call created")
        let csrfHtmlTag = document.getElementById("csrf");
        let self = this;
        if (csrfHtmlTag === null) {
            $.get("/get-csrf-coockies/", function (data, status) {
                if (status === "success") {
                    console.log("Set csrf");
                    self.csrftoken = data;
                    $.ajaxSetup({
                        headers: {
                            'X-CSRFToken': self.csrftoken
                        }
                    });
                    self.$axios.defaults.headers.post['X-CSRFToken'] = self.csrftoken
                    self.$axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
                }
            });
        } else {
            self.csrftoken = csrfHtmlTag.querySelector('[name=csrfmiddlewaretoken]').value;
            $.ajaxSetup({
                headers: {
                    'X-CSRFToken': self.csrftoken
                }
            });
            self.$axios.defaults.headers.post['X-CSRFToken'] = self.csrftoken
            self.$axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
        }
    }
}