<template xmlns="http://www.w3.org/1999/html">

  <div>
    <div class="modal-backdrop" v-show="showForm"></div>
    <div class="card sc-modal" v-show="showForm && !welcome">
      <div class="close sc">
        <i @click.self.prevent="showForm=false;"
           class="fas fa-times d-flex justify-content-center align-items-center"></i>
      </div>
      <div class="card-body sc">
        <h4 class="card-title sc">Вмикайся!</h4>
        <p>Ми шукаємо стартаперів готових до тестування своєї культури</p>
        <form id="subscriptionForm">
          <label class="form-label sc">Електронна пошта</label>
          <input v-bind:style="{'border-color':(subscribeError === '')?'#C2C2C2':'#FF4D4D'}"
                 v-model="subscriberEmail"
                 type="text" placeholder="info@company.com" name="subscriber" id="subscriber" />
          <button class="btn btn-primary"
                 :class="{active: subscriberEmail !== ''}" @click.self.prevent="subscribe" type="button">БУТИ ПЕРШИМ</button>
        </form>
      </div>
    </div>
    <div class="card sc-modal-2" v-show="welcome">
      <div class="close sc">
        <i @click.self.prevent="showForm=false;welcome=false;"
           class="fas fa-times d-flex justify-content-center align-items-center"></i>
      </div>
      <div class="card-body sc">
        <h4 class="card-title sc">Вітаємо!</h4>
        <p>Поки ми готуємо MVP для тебе,<br> можеш подивитися
          <a @click.preventDefault="printResults" href="#">результат</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import {mapState} from 'pinia';
import {useMainStore} from "@/stores/main";

export default {
  name: 'subscribe-modal',

  data: function () {
    return {
      subscriberEmail: "",
      subscribeError: "",
      showForm: false,
      welcome: false
    }
  },
  props: ["open"],
  watch: {
    subscriberEmail: function (newSubscriberEmail) {
      if (newSubscriberEmail !== "") {
        this.subscribeError = "";
      }
    },
    open: function() { // watch it
      this.showForm = true;
    }
  },
  computed: {
    ...mapState(useMainStore, ["tgpd"])
  },
  methods: {
    subscribe() {
      if (this.subscriberEmail === "") {
        this.subscribeError = "Заповніть порожнє поле";
      } else {
        let self = this;
        self.welcome = true;
        $.post("/subscribe/" + window.location.search, $("#subscriptionForm").serialize(), function (data, status) {
          if (status === "success") {
            self.welcome = true;
            console.log(data);
          }
        }, "json")
            .fail(function (response) {
              self.subscribeError = response.responseJSON.error;
            });
      }
    },
    printResults: async function (event) {
      console.log("printing..");
      let self = this;

      htmlToImage.toPng(document.getElementById('printContent'))
          .then(function (dataUrl) {
            download(dataUrl, self.tgpd.team + ".png");
          });

      console.log("done");
    }
  }
};
</script>

<style scoped>

.modal-backdrop {
  opacity: 0.9!important;
  z-index: 1;
}

.card.sc-modal {
  width: 530px;
  height: 490px;
  margin: 0;
  border-style: none;
  position: absolute;
  bottom: 30%;
  left: 30%;
  z-index: 2;
  background: none;
}

.card.sc-modal-2 {
  width: 530px;
  height: 250px;
  margin: 0;
  border-style: none;
  position: absolute;
  bottom: 40%;
  left: 30%;
  z-index: 2;
  background: none;
}

.close.sc {
  width: 30px;
  height: 30px;
  border: 2px solid #C4C4C4;
  border-radius: 15px;
  text-align: center;
  position: relative;
  top: 50px;
  left: 470px;
}

.close.sc i:hover {
  cursor: pointer;
}

.close.sc i {
  font-size: 10px;
  height: 26px;
}

.card-body.sc {
  box-shadow: 0 0 50px grey;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  border-style: solid;
  border-color: #FFFFFF;
  padding: 60px 62px 65px 60px;
  background-color: white;
}

.card-body.sc p {
  font-size: 24px;
  margin-bottom: 50px;
}

.card-body.sc input {
  width: 400px;
  height: 56px;
  color: #C4C4C4;
  border-radius: 4px;
  border: 2px solid #C4C4C4;
  padding-left: 32px;
}

.card-body.sc button {
  width: 400px;
  height: 56px;
  border-color: #F3F3F3;
  background: #F3F3F3;
  color: #C4C4C4;
  font-size: 16px;
  margin-top: 16px;
  font-weight: bold;
}

.btn.btn-primary.active {
  color: black!important;
}

input:focus-visible {
  box-shadow: none;
  outline: 0 none;
}

.form-label.sc {
  font-size: 18px;
}

.card-title.sc {
  font-size: 40px;
  line-height: 29px;
  margin: 0 0 16px;
}
</style>
