<template>
  <div id="email-confirmation-page" class="row">
    <left-info-panel></left-info-panel>
    <div class="col-6 d-flex justify-content-center align-items-center bg-secondary sc-right-block">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="card sc">
                <div v-show="withKey" class="card-body sc">
                  <h4 class="card-title sc">Пошту підтверджено</h4>
                  <label class="d-block sc-helper sc-fs-14 mt-3">
                    Тепер, коли твій акаунт було успішно зареєстровано, можеш повернутися до найважливіших сенсів.
                  </label>
                  <label class="form-label sc"><a class="pe-auto" href="#/signin/">Увійти</a></label>
                </div>
                <div v-show="!withKey" class="card-body sc">
                  <h4 class="card-title sc">Підтверди пошту</h4>
                  <p class="d-block sc-helper sc-fs-14 mt-5">
                    Тобі, на пошту повинен був прийти лист із посиланням для активації аккаунту.
                    Перейди за ним, аби почати користуватись Senscreed.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import LeftInfoPanel from "@/components/leftInfoPanel";
import {csrfAjaxMixin} from "@/mixins/csrf";
import SocialLinks from "@/components/socialLinks.vue";

export default {
  name: "email-confirmation-page",
  components: {SocialLinks, LeftInfoPanel},
  delimiters: ['[[', ']]'],
  mixins: [csrfAjaxMixin],
  data: function () {
    return {
      withKey: false,
    }
  },
  created() {
    let self = this;
    if (this.$route.params.key !== undefined) {
      this.withKey = true;
      if (this.$route.params.key !== "google-auth") {
        this.$axios.get('/accounts/confirm-email/' + this.$route.params.key + '/').then(function (response) {
          self.$log.info("Method: created. Url: '/accounts/confirm-email/'. Action: Email confirmation. Output: ", response);
        }).catch(function (error) {
          self.$log.error("Method: created. Url; '/accounts/confirm-email/'. Action: Email confirmation. Output: ", error);
        });
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #email-confirmation-page {
    height: 100%;
    padding: 20px;
    margin: 0;
  }

  .sc-right-block {
    height: 100%;
    border-radius: 10px;
  }

  .card.sc {
    width: 400px;
    height: 250px;
    margin: 0;
    position: relative;
  }

  .card-body.sc {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 45px 25px 10px;
  }

  .card-title.sc {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-left: 25px;
    margin-bottom: 10px;
  }

  .check .card-title.sc {
    margin-bottom: 35px;
  }

  .sc-helper {
    margin-left: 25px;
  }

  .form-label.sc {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 8px;
    margin-left: 25px;
    font-size: 16px;
  }

  .sc-fs-14 {
    font-size: 14px!important;
  }

</style>
