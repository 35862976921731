<template xmlns="http://www.w3.org/1999/html">

  <div>
    <div class="modal-backdrop" v-show="showForm"></div>
    <div class="card sc" v-show="showForm">
      <div class="card-header border-0 d-flex justify-content-end p-3">
        <i @click.prevent="showForm=false" class="fas fa-times fs-3 text-black cursor-pointer"></i>
      </div>
      <div class="card-body p-0">
        <div class="container p-5 pt-0">
          <div class="row g-0">
            <div class="col-md-12">
              <h2>Ще кілька секунд..</h2>
              <label class="form-label fs-5">Поки очікуєш на результат, можеш підтримати нас.</label>
            </div>
          </div>
          <div class="row g-0 mt-4">
            <div class="col">
              <a class="btn btn-primary d-block rounded-3 text-uppercase"
                 target="_blank" href="https://www.buymeacoffee.com/senscreed" role="button">
                Задонатити<i class="icon-donate sc position-absolute end-25"></i>
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'donate-modal',

  data: function () {
    return {
      showForm: false,
    }
  },
  props: ["open"],
  watch: {
    open: function() { // watch it
      this.showForm = true;
    }
  },

  methods: {
  }
};
</script>

<style scoped>

.modal-backdrop {
  opacity: 0.9!important;
  z-index: 1;
}

.card.sc {
  width: 465px;
  height: 290px;
  position: absolute;
  bottom: 40%;
  left: 30%;
  z-index: 2;
}

.sc .card-header {
  background-color: #FFFFFF;
}

.btn-primary {
  height: 56px;
  padding-top: 4%;
}

.pt-0 {
  padding-top: 0!important;
}

.icon-donate.sc{
  height: 24px;
  width: 24px;
  background:url('~@/assets/donate.png') no-repeat;
  background-size: 24px 24px;
  display: inline-block;
  margin-left: 15%;
}

.cursor-pointer {
  cursor: pointer;
}

</style>
