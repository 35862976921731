<template>
  <div id="page21" class="h-100">
      <header-title :displayStepInfo="true">
        <template  v-slot:company>
          <company-info></company-info>
        </template>
        <template v-slot:stepInfo>Наші спільні цінності</template>
        <template  v-slot:step>3</template>
        <template  v-slot:text>Оберіть 10 сенсів, важливих для нашої взаємодії</template>
      </header-title>

    <div class="row row-cols-5 d-flex flex-grow-1 justify-content-center step-main-row middle" v-bind:class="{ 'sc-disabled-area': !showReady }">
      <div class="col values-category" v-for="(pValues, player) in playerGroups">
        <div class="row text-center d-flex align-items-center border border-3 border-white values-block"
             v-for="item in pValues" @click="pick">
          <div class="col">
            <span :attr-id="item.id">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-cols-3 step-main-row bottom">
      <players-state :current-phase="21">
        <template v-slot:hintContent>
          <p>Оберіть 10 сенсів, які описують нашу співпрацю в команді чи компанії, підсилюють її та мотивують рухатися
            вперед.</p>

          <p>У кожній колонці обирайте від 1 до 10 сенсів, головне, аби сумарно у вас вийшло 10.</p>

          <p>Після того, як ви зробите вибір, тисніть «Далі» аби перейти до наступного завдання.</p>

          <p><strong>PS</strong> Думайте про взаємодію, а не тільки про власні бажання.</p>
        </template>
      </players-state>
      <div class="col d-flex align-items-center">
        <progress-bar @completed="onProgressBarCompleted" :total="10" ref="progressBarCmpS21"></progress-bar>
      </div>
      <div class="col d-flex align-items-center">
        <div class="row d-flex flex-grow-1">
          <div v-bind:class="{ invisible: !showWait  }" class="col-9 d-flex justify-content-center align-items-center">
            <span>Очікуємо на решту команди</span>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <next-button @goto="onGoto" :ready-next="readyNext">
              <template v-slot:content v-if="showReady">
                <span>Готово</span>
              </template>
              <template v-slot:content v-else>
                <span>Далі</span>
                <i class="fas fa-arrow-right sc"></i>
              </template>
            </next-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'
import playersState from "./playersState";
import companyInfo from "./companyInfo";
import headerTitle from "./headerTitle";
import progressBar from "./progressBar";
import nextButton from "./nextButton";
import {mapState, mapWritableState, mapActions} from 'pinia';
import {useMainStore} from "@/stores/main";

export default {
  name: 'section21',
  components: {
    playersState,
    companyInfo,
    headerTitle,
    progressBar,
    nextButton
  },
  data: function () {
    return {
      readyNext: false,
      showWait: false,
      showReady: true,
      isGameOver: false
    }
  },
  computed: {
    ...mapActions(useMainStore, ['fetchPhase21']),
    ...mapState(useMainStore, ["playerGroups", "top10", "tgpd", "firstClick"]),
    ...mapWritableState(useMainStore, ["isSection21", "isSection4", "isSection7",
      "resultedValues", "phase2Results", "topValues", "leftValues", "leftValuesMock"])
  },
  created() {
    if (!Object.keys(this.playerGroups).length || !this.tgpd.game) {
      this.fetchPhase21;
    }
  },
  watch: {
    firstClick: function (val) {
      if (val === true) {
        this.postPhase21 = true;
        let self = this;
        setTimeout(()=>{
          for (let currentValue of self.top10) {
            let button = $("span:contains('" + currentValue.title + "')").closest(".values-block");
            button.addClass("sc-value-selected");
            self.$refs.progressBarCmpS21.increase();
          }

          self.showReady = false;
          self.readyNext = false;

          self.refreshIntervalID = setInterval(self.getAllPlayersResults, 1000);
        }, 2000);

      }
    }
  },
  methods: {
    onProgressBarCompleted() {
      this.readyNext = true;
    },
    pick: function (event) {
      if (event) {
        let button = $(event.target).closest(".values-block");
        if (!button.hasClass("sc-value-selected")) {
          if (this.$refs.progressBarCmpS21.getCurrent() !== 10) {
            button.addClass("sc-value-selected");
            this.$refs.progressBarCmpS21.increase();
          }
        } else {
          button.removeClass("sc-value-selected");
          this.$refs.progressBarCmpS21.decrease();
          this.readyNext = false;
        }
      }
    },
    onGoto() {
      if (this.$refs.progressBarCmpS21.getCurrent() === 10) {
        let top10 = $(".sc-value-selected").map(function (index, item) {
          let sp = $(item).find("span");
          return {title: sp.text(), id: +sp.attr("attr-id")};
        });

        if (this.readyNext && !this.showReady) {

          if (this.isGameOver) {
            this.isSection21 = false;
            this.isSection7 = true;
            this.$router.push({ path: '/phase7' })
          } else {
            this.leftValuesMock = [];
            for (let i = this.topValues.length + 1; i <= 10; i++) {
              this.leftValuesMock.push({title: i});
            }
            this.isSection21 = false;
            this.isSection4 = true;
            this.$router.push({ path: '/phase4' })
          }
          return;
        }

        let self = this;
        let requestData = {
          "game": this.tgpd.game,
          "team": this.tgpd.team,
          "player": this.tgpd.player,
          "results": top10.toArray()
        };

        if (!self.postPhase21) {
          this.$axios.post("/phase21/", requestData).then(function(response) {
            self.showReady = false;
            self.readyNext = false;
            self.refreshIntervalID = setInterval(self.getAllPlayersResults, 1000);
          }).catch(function (error) {
            console.error(error);
          });
          self.postPhase21 = true;
        }
      } else {
        this.showWait = true;
        let self = this;
        setTimeout(()=>self.showWait = false, 3000);
      }
    },
    getAllPlayersResults() {
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team,
        "phase": "Phase21"
      };
      this.$axios.post("/phase3/", requestData).then(function(response) {
        self.activeUser = {
          name: self.tgpd.player,
          values: Array.from(response.data[self.tgpd.player])
        };
        let usersValues = response.data;
        let isFinished = true;
        for (let player in usersValues) {
          if (player !== self.tgpd.player && usersValues[player].length === 0) {
            isFinished = false;
            self.showWait = true;
            self.readyNext = false;
            break;
          }
        }
        if (isFinished) {
          console.log("All players sorted values!");
          clearInterval(self.refreshIntervalID);
          self.showWait = false;
          self.readyNext = false;
          self.processUserValues(usersValues);
        }
      }).catch(function (error) {
        console.error(error);
      });
    },
    processUserValues(usersValues) {
      let self = this;
      let allValues = [];
      let userValuesSorted = {};
      for (let key in usersValues) {
        let currentUserValues = [];
        userValuesSorted[key] = [];
        for (let index=0; index<usersValues[key].length; index++) {
          let item = usersValues[key][index];
          userValuesSorted[key].push(item);
          currentUserValues.push({id:item.id, rating: 10 - index, title: item.title});
        }
        allValues = allValues.concat(currentUserValues);
      }

      let valuesCount = {};
      allValues.forEach(function(item) {
        if (item.id in valuesCount) {
          valuesCount[item.id].count += 1;
          valuesCount[item.id].rating += item.rating;
        } else {
          valuesCount[item.id] = {count: 1, rating: item.rating, title: item.title};
        }
      });

      let valuesRating = {};
      let topValues = [];
      let topValuesDict = {};
      let leftValues = [];

      let threshold = Object.keys(usersValues).length * 0.51;
      Object.entries(valuesCount).forEach(function(el){
        let curRating = Math.pow(10, el[1].count - 1) + el[1].rating;
        valuesRating[el[0]] = curRating;
        if (el[1].count >= threshold) {
          topValues.push({id: el[0], title: el[1].title, rating: curRating});
          topValuesDict[el[0]] = el[1].title;
        } else {
          leftValues.push({id: el[0], title: el[1].title, rating: curRating});
        }
      });

      for (let key in userValuesSorted) {
        for (let i=0; i<userValuesSorted[key].length; i++) {
          userValuesSorted[key][i].order = valuesRating[userValuesSorted[key][i].id];
          userValuesSorted[key][i].inTop = userValuesSorted[key][i].id in topValuesDict;
        }
      }

      let fnCmp = (l, r) => {
        return (r.rating - l.rating) || (l.title.localeCompare(r.title));
      }

      for (let key in userValuesSorted) {
        userValuesSorted[key].sort(fnCmp);
      }

      topValues.sort(fnCmp);

      leftValues.sort(fnCmp)

      if (topValues.length !== 0) {
        let requestData = {
          "game": this.tgpd.game,
          "team": this.tgpd.team,
          "top": topValues,
          "left": leftValues
        };

        this.$axios.post("/phase3save/", requestData).then(function(response) {
          if ((10 - topValues.length) === 0) {
            self.refreshIntervalID = setInterval(self.getFinalResult, 1000)
          } else {
            self.topValues = topValues;
            self.leftValues = leftValues;
            self.isGameOver = false;
            self.readyNext = true;
          }
        }).catch(function (error) {
          console.error(error);
        });
      } else {
        console.log("No matches!!! Redirecting to alternative phase");
      }
    },
    getFinalResult() {
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team
      };
      this.$axios.post("/phase7/", requestData).then(function(response) {
        if (response.data && response.data["resultedValues"].length > 0) {
          console.log("Received final results!", response.data);
          clearInterval(self.refreshIntervalID);
          self.resultedValues = response.data["resultedValues"];
          self.phase2Results = response.data["phase2Results"];
          self.isGameOver = true;
          self.readyNext = true;
        }
      }).catch(function (error) {
        console.error(error);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.values-category {
  padding: 0 8px;
}

.value-header {
  height: 6%;
  max-height: 30px;
  min-height: 24px;
  margin: 0 0 3%;
  border-radius: 4px;
  color: #C4C4C4;
  background: #F3F3F3;
}

.values-block {
  border-radius: 4px;
  min-height: 30px;
  max-height: 56px;
  margin: 0 0 3%;
  height: 7%;
  color: #C4C4C4;
  font-size: 14px;
}

.values-category:hover .values-block span {
  color: black;
}

.values-block:hover {
  border: solid 3px;
  border-color: rgba(var(--bs-secondary-rgb),var(--bs-border-opacity))!important;
  cursor: pointer;
}

.sc-value-selected {
  border: 3px solid;
  border-color: rgba(var(--bs-secondary-rgb),var(--bs-border-opacity))!important;
  color: black;
}

.disabled-area {
  position: absolute;
  width: 100%;
  height: 62%;
  z-index: 10;
  top: 10%;
  bottom: 10%;
}

</style>
