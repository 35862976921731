import VueLogger from 'vue-logger-plugin'
import axios from 'axios'
import $ from "jquery";

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

// after hook to send log messages to api endpoint
const ServerLogHook = {
    run: (event) => {
        let csrfHtmlTag = document.getElementById("csrf");
        let csrfToken = getCookie('csrftoken');
        if (csrfToken === null && csrfHtmlTag === null) {
            $.get("/get-csrf-coockies/", function (data, status) {
                if (status === "success") {
                    csrfToken = data;
                }
            });
        } else {
            csrfToken = csrfHtmlTag.querySelector('[name=csrfmiddlewaretoken]').value;
        }

        const instance = axios.create();
        instance.defaults.headers.post['X-CSRFToken'] = csrfToken

        instance.post('/newrelic/log/v1/', {
            "timestamp": new Date().valueOf(),
            "logtype": event.level,
            "message": event.argumentArray,
            "service": "Senscreed UI",
        })
    }
}

// define options
const options = {
    enabled: true,
    level: 'info',
    afterHooks: [ServerLogHook]
}

// export logger with applied options
export const CustomVueLogger = new VueLogger(options);