import Vue from 'vue'
import babelPolyfill from 'babel-polyfill'
import {createPinia, PiniaVuePlugin} from 'pinia'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import App from "./App";
import {useMainStore} from "@/stores/main";
import signin from "@/components/signin";
import signup from "@/components/signup";
import createGame from "@/components/createGame";
import generatedLink from "@/components/generatedLink";
import profile from "@/components/profile";
import login from "@/components/login";
import section1 from "@/components/section1";
import section2 from "@/components/section2";
import section21 from "@/components/section21";
import section4 from "@/components/section4";
import section6 from "@/components/section6";
import section7 from "@/components/section7";
import axios from "axios";
import forgotPassword from "@/components/forgotPassword";
import resetPassword from "@/components/resetPassword";
import emailConfirmation from "@/components/emailConfirmation";

axios.interceptors.response.use((response) => response, async (inst) => {

    let config = (inst && inst.config) ? inst.config : {}
    config.currentRetryAttempt = config.currentRetryAttempt || 0;
    const retry = 5;
    const retryDelay = 1000;
    const retryRanges = [
        // 1xx - Retry (Informational, request still processing)
        // 2xx - Do not retry (Success)
        // 3xx - Do not retry (Redirect)
        // 4xx - Do not retry (Client errors)
        // 429 - Retry ("Too Many Requests")
        // 5xx - Retry (Server errors)
        [100, 199],
        [429, 429],
        [500, 599],
    ];

    if (inst.response) {
        let isInRange = false;
        for (const [min, max] of retryRanges) {
            if (inst.response.status >= min && inst.response.status <= max) {
                isInRange = true;
                break;
            }
        }
        if (isInRange && config.currentRetryAttempt < retry) {
            config.currentRetryAttempt += 1;
            inst.config = config;
            console.warn(`Retrying ${config.url} with status code ${inst.response.status}. 
            Attempt ${config.currentRetryAttempt}/${retry}`);
            const onBackoffPromise= new Promise(function (resolve, ) {
                setTimeout(resolve, retryDelay)
            });

            return Promise.resolve()
                .then(async () => onBackoffPromise)
                .then(async () => axios.request(inst.config));
        }
    }

    return Promise.reject(inst);
})

Vue.prototype.$axios = axios

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VueRouter)
Vue.config.productionTip = false;

Vue.use(VueCookies);

const router = new VueRouter({
    routes: [
        {
            path: '/email-confirmation/:key?/',
            component: emailConfirmation,
        },
        {
            path: '/reset-password/:key/',
            component: resetPassword,
        },
        {
            path: '/forgot-password/',
            component: forgotPassword,
        },
        {
            path: '/signin/:error?',
            component: signin,
            name: 'signin'
        },
        {
            path: '/signup/:error?',
            component: signup
        },
        {
            path: '/create/',
            component: createGame,
            meta: {requiresAuth: true},
        },
        {
            path: '/link/:team?',
            component: generatedLink,
            meta: {requiresAuth: true},
        },
        {
            path: '/profile/',
            component: profile,
            meta: {requiresAuth: true},
        },
        {
            path: '/login/:hash',
            component: login
        },
        {
            path: '/phase1',
            component: section1
        },
        {
            path: '/phase2',
            component: section2
        },
        {
            path: '/phase21',
            component: section21
        },
        {
            path: '/phase4',
            component: section4
        },
        {
            path: '/phase6',
            component: section6
        },
        {
            path: '/phase7',
            component: section7
        },
        {
            path: '/',
            component: function () {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return import("@/components/mobileLanding")
                } else {
                    return import("@/components/signin.vue")
                }
            }
        }
    ]
})

router.beforeEach(async (to, _, next) => {
    if (to.meta.requiresAuth) {
        try {
            const res = await axios.get('/isauthenticated/');
            next();
        } catch (error) {
            next({name: 'signin'})
        }
    } else {
        const userStore = useMainStore(pinia);
        next();
    }
})

const app = new Vue({
    router,
    pinia
}).$mount('#app')