import { render, staticRenderFns } from "./playersState.vue?vue&type=template&id=138ea6e1&scoped=true"
import script from "./playersState.vue?vue&type=script&lang=js"
export * from "./playersState.vue?vue&type=script&lang=js"
import style0 from "./playersState.vue?vue&type=style&index=0&id=138ea6e1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "138ea6e1",
  null
  
)

export default component.exports