<template>
  <div class="card sc-modal" v-show="showForm">
    <div id="printContent" class="card sc m-0">
      <div class="card-body sc m-0">
        <h4 class="card-title sc">Спільні цінності</h4>
        <hr class="m-0 p-0 sc">
        <div class="row row-cols-2 sc-pl20-pr-40 sc-title-container">
          <div class="col d-flex justify-content-start align-items-center p-0">
            <strong class="fs-6 fw-bolder w-100">{{ team }}</strong>
          </div>
          <div class="col d-flex justify-content-end align-items-center p-0">
            <label class="col-form-label fs-6 fw-light m-0">{{ playersCount }} ос.</label>
          </div>
        </div>
        <hr class="p-0 sc sc-mb-10">
        <div class="col d-flex flex-column align-items-center">
          <div class="row text-center d-flex values-block-top" v-for="value in top.slice(0, 3)">
            <div class="col-11 d-flex flex-grow-1 justify-content-center align-items-center p-0">
              <span class="p-0">{{ value.title }}</span>
            </div>
          </div>
        </div>
        <div class="col d-flex flex-column align-items-center sc-mt-15">
          <div class="row text-center d-flex values-block-top" v-for="value in top.slice(3)">
            <div class="col-11 d-flex flex-grow-1 justify-content-center align-items-center p-0">
              <span class="p-0">{{ value.title }}</span>
            </div>
          </div>
        </div>
        <hr class="p-0 sc sc-mt25-mb15">
        <p class="text-center"><b>{{ voteResult }}%</b> погодились із цим результатом</p>
      </div>
    </div>
  </div>

</template>

<script>

import * as htmlToImage from "html-to-image";
import download from "downloadjs";

export default {
  name: 'results-to-download',

  data: function () {
    return {
      showForm: false,
    }
  },
  props: ["open", "top", "team", "playersCount", "voteResult", "startDate"],
  watch: {
    open: function() {
      document.querySelector('body').style.overflow = "hidden";
      this.showForm = true;
      console.log("printing..");
      let self = this;
      setTimeout(function (){
        htmlToImage.toPng(document.getElementById('printContent'))
            .then(function (dataUrl) {
              download(dataUrl, self.team + "-" + self.startDate + ".png");
              console.log("done");
              self.showForm = false;
              document.querySelector('body').style.overflow = "visible";
            });
      }, 1000);
    }
  },
};
</script>

<style scoped>

#printContent {
  background: none;
}

.card.sc-modal {
  width: 400px;
  max-height: 470px;
  margin: 0;
  border-style: none;
  position: absolute;
  bottom: 100%;
  left: 100%;
  z-index: -1;
  background: none;
}

.card-body.sc {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0 25px 15px 25px;
  background-color: white;
}

.card-body.sc p {
  font-size: 16px;
}

.card-title.sc {
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.values-block-top {
  border: 3px solid var(--bs-white);
  border-radius: 4px;
  min-height: 40px;
  max-height: 56px;
  color: black;
  width: 300px;
  margin: 0 0 -3px;
  height: 9%;
}

hr.sc {
  width: 100%;
  border-color: var(--bs-light);
}

.sc-mb-10 {
  margin: 0 0 10px;
}

.sc-mt-15 {
  margin-top: 15px;
}

.sc-mt25-mb15 {
  margin: 25px 0 15px;
}

.sc-pl20-pr-40 {
  padding-left: 35px;
  padding-right: 40px;
}

</style>
