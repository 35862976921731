<template xmlns="http://www.w3.org/1999/html">

  <div>
    <div class="modal-backdrop" v-show="showForm"></div>
    <div class="card sc" v-show="showForm">
      <div class="card-header border-0 d-flex justify-content-between p-3">
        <ul class="nav card-header-s w-75">
          <li class="nav-item"><a class="nav-link fw-bold text-black"
                                  @click.prevent="showIdeas=false;showFeedback=true;showSuggestions=false" href="#">Фідбек</a>
            <div :class="{'bg-light': !showFeedback}"
                 class="mx-auto bg-secondary w-75 sc-line"></div>
          </li>
          <li class="nav-item d-flex flex-column">
            <a class="nav-link fw-bold text-black"
               @click.prevent="showIdeas=true;showFeedback=false;showSuggestions=false" href="#">Пропозиції</a>
            <div :class="{'bg-light': !showIdeas}"
                 class="mx-auto bg-secondary w-75 sc-line"></div>
          </li>
<!--          <li class="nav-item"><a class="nav-link fw-bold text-black"-->
<!--                                  @click.prevent="showIdeas=false;showFeedback=false;showSuggestions=true" href="#">Поради</a>-->
<!--            <div :class="{'bg-light': !showSuggestions}"-->
<!--                 class=" mx-auto bg-secondary w-75 sc-line"></div>-->
<!--          </li>-->
        </ul>
        <button @click.prevent="showForm=false" class="btn-close ml-auto"></button>
      </div>
      <div class="card-body">
        <div class="container p-3" v-show="showFeedback">
          <form id="feedbackForm">
            <h4>{{ questions["main"] }}</h4>
            <div class="row g-0 mt-15">
              <div class="col-md-12">
                <label class="form-label">{{ questions["q1"] }}</label>
                <div>
                  <i @mouseover="hoverIn" @mouseout="hoverOut" @click="setRating(index, $event)"
                      class="fas fa-star text-light cursor-pointer fs-3 mr-15" v-for="index in 5" :key="index"></i>
                </div>
                <input name="q1" type="hidden" value=0 />
              </div>
            </div>
            <div class="row g-0 mt-15">
              <div class="col-md-12">
                <label class="form-label">{{ questions["q2"] }}</label>
                <div>
                  <i @mouseover="hoverIn" @mouseout="hoverOut" @click="setRating(index, $event)"
                      class="fas fa-star text-light cursor-pointer fs-3 mr-15" v-for="index in 5" :key="index"></i>
                </div>
                <input name="q2" type="hidden" value=0 />
              </div>
            </div>
            <div class="row g-0 mt-15">
              <div class="col-md-12"><label class="form-label">{{ questions["q3"] }}</label>
                <div>
                  <textarea name="q3" class="w-100 border border-light rounded-2" style="height: 100px;"></textarea>
                </div>
              </div>
            </div>
          </form>
          <div class="row g-0 mt-4">
            <div class="col-md-6 text-center p-1">
              <a @click.prevent="sendFeedback" :class="{ 'd-block': !onceFeedback, 'd-none': onceFeedback }"
                 class="btn btn-primary border border-secondary border-3" role="button">
                Надіслати
              </a>
              <a @click.prevent="" :class="{ 'd-block': onceFeedback, 'd-none': !onceFeedback }"
                 class="btn btn-primary border border-secondary border-3 text-black fw-bold disabled" role="button">Надіслано</a>
            </div>
            <div class="col-md-6 text-center p-1">
              <a class="btn d-block rounded-3 border-secondary border border-3"
                 target="_blank" href="https://buy.stripe.com/test_6oEfZncD8aGDd4Q7ss"
                 role="button">Донат</a>
            </div>
          </div>
        </div>
        <div class="container p-3" v-show="false">
          <div class="row g-0 mt-15" v-for="(value, index) in suggestions">
            <div class="col-md-12">
              <h1>Порада #{{ index + 1 }}</h1>
              <label class="form-label">{{ value }}</label>
            </div>
          </div>
          <div class="row g-0 mt-4">
            <div class="col-md-6 text-center p-1">
              <a class="btn d-block rounded-3 border-secondary border border-3"
                 target="_blank" href="https://buy.stripe.com/test_6oEfZncD8aGDd4Q7ss"
                 role="button">Донат</a>
            </div>
          </div>
        </div>
        <div class="container" v-show="showIdeas">
          <h4>Що саме тебе цікавить?</h4>
          <p>Зараз ми тестуємо наш сервіс, та хочемо зрозуміти, яке майбутнє рішення буде для тебе корисним</p>
          <div class="row g-0">
            <form id="ideasForm">
              <div class="col-md-12 p-2 px-0" v-for="(value, index) in ideas">
                <div class="form-check">
                  <input value="true" :id="'idea-' + index" :name="'idea-' + index" class="form-check-input" type="checkbox" />
                  <label @mouseover.self="onHover" @mouseout.self="onHover"
                         class="form-check-label" :for="'idea-' + index">{{ value }}</label>
                </div>
              </div>
            </form>
          </div>
          <div class="row g-0 mt-4">
            <div class="col-md-6 text-center p-1">
              <a @click.prevent="sendIdeas" :class="{ 'd-block': !onceIdeas, 'd-none': onceIdeas }"
                 class="btn btn-primary border border-secondary border-3" role="button">Надіслати</a>
              <a @click.prevent="" :class="{ 'd-block': onceIdeas, 'd-none': !onceIdeas }"
                 class="btn btn-primary border border-secondary border-3 text-black fw-bold disabled" role="button">Надіслано</a>
            </div>
            <div class="col-md-6 text-center p-1">
              <a class="btn d-block rounded-3 border-secondary border border-3"
                 target="_blank" href="https://buy.stripe.com/test_6oEfZncD8aGDd4Q7ss" role="button">Донат</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'pinia';
import {useMainStore} from "@/stores/main";
import $ from 'jquery'

export default {
  name: 'whats-next-modal',

  data: function () {
    return {
      showForm: false,
      onceIdeas: false,
      showIdeas: false,
      showFeedback: true,
      onceFeedback: false,
      showSuggestions: false,
      ideas: [],
      questions: {},
      suggestions: []
    }
  },
  props: ["open"],
  watch: {
    open: function() { // watch it
      this.showForm = true;
      let self = this;
      this.$axios.get("/whatsnext/?g=" + this.gameHash).then(function(response) {
        self.ideas = response.data.ideas;
        self.questions = response.data.questions;
        self.suggestions = response.data.suggestions;
      });
    }
  },
  computed: {
    ...mapState(useMainStore, ["tgpd", "gameHash"])
  },
  methods: {
    sendIdeas() {
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team,
        "player": this.tgpd.player,
        "type": "ideas",
        "answers": Object.assign({}, ...$("#ideasForm").serializeArray().map((x) => ({[x.name]: !!x.value})))
      };
      this.$axios.post('/whatsnext/', requestData)
          .then(function (response) {
            self.onceIdeas = true;
            self.$log.info("Method: 'sendIdeas'. Url: '/whatsnext/'. Output: ", response);
          }).catch(function (error) {
        let errMsg = "";
        if (error.response) {
          errMsg = error.response;
        } else if (error.request) {
          errMsg = error.request;
        } else {
          errMsg = error.message;
        }
        self.$log.error("Method: 'sendIdeas'. Url: '/whatsnext/'. Output: ", errMsg);
      });
    },
    sendFeedback() {
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team,
        "player": this.tgpd.player,
        "type": "feedback",
        "answers": Object.assign({}, ...$("#feedbackForm").serializeArray().map((x) =>
            ({[x.name]: (!+x.value) ? x.value : +x.value})))
      };
      this.$axios.post('/whatsnext/', requestData)
          .then(function (response) {
            self.$log.info("Method: 'sendFeedback'. Url: '/whatsnext/'. Output: ", response);
            self.onceFeedback = true;
          }).catch(function (error) {
        let errMsg = "";
        if (error.response) {
          errMsg = error.response;
        } else if (error.request) {
          errMsg = error.request;
        } else {
          errMsg = error.message;
        }
        self.$log.error("Method: 'sendFeedback'. Url: '/whatsnext/'. Output: ", errMsg);
      });
    },
    hoverIn(event) {
      let currentStar = $(event.target);
      currentStar.addClass("text-secondary").removeClass("text-light");
      currentStar.prevAll().addClass("text-secondary").removeClass("text-light");
    },
    hoverOut(event) {
      let currentStar = $(event.target);
      currentStar.removeClass("text-secondary").addClass("text-light");
      currentStar.prevAll().removeClass("text-secondary").addClass("text-light");
    },
    setRating(index, event) {
      let currentStar = $(event.target);
      currentStar.removeClass("text-light").addClass("selected");
      currentStar.prevAll().removeClass("text-light").addClass("selected");
      currentStar.nextAll().addClass("text-light").removeClass("selected");
      currentStar.parent().parent().find("input").val(+index);
    }
  }
};
</script>

<style scoped>

.modal-backdrop {
  opacity: 0.9!important;
  z-index: 1;
}

.card.sc {
  width: 500px;
  height: 600px;
  position: absolute;
  bottom: 10%;
  left: 30%;
  z-index: 2;
}

.sc .card-header {
  background-color: #FFFFFF;
}

.sc-line {
  padding: 2px;
}

.mr-15 {
  margin-right: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.form-check-label:hover {
  cursor: pointer;
}

.selected {
  color: var(--bs-secondary)!important;
}

.border-secondary:hover {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-primary)!important;
}

.border-secondary.disabled {
  border-color: var(--bs-light)!important;
}

</style>
